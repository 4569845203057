import React, { useMemo, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import block from 'bem-cn';

import Paginator from 'components/Paginator';
import Select from 'components/Select/desktop';

import { formatNumberWithLimit } from 'shared/helpers/formatNumber';

import TableRow from '../TableRow/TableRow';
import TableHead from '../TableHead/TableHead';

import './Table.scss';

const b = block('payment-history-table-cash');

const Table = ({ currentPage, onPageClick, onItemsOnPageChanged }) => {
  const totalCount = useSelector(state => state.reports.paymentHistory.details.totalCount, shallowEqual);
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const paymentHistory = useSelector(state => state.reports.paymentHistory, shallowEqual);
  const itemsOnPageFilters = [
    { value: 10, name: '10' },
    { value: 25, name: '25' },
    { value: 50, name: '50' },
    { value: 100, name: '100' },
  ];
  const [itemsOnPage, setItemsOnPage] = useState({ value: 10, name: '10' });

  const selectItemChangedHandler = itemValue => {
    setItemsOnPage({ value: itemValue, name: itemValue });
    onItemsOnPageChanged(itemValue);
  };

  const rows = useMemo(
    () => paymentHistory.transfers.map(item => <TableRow key={item.id} item={item} locale={locale} />),
    [paymentHistory, locale],
  );
  return (
    <div className={b()}>
      <div className={b('total-header')}>{locale.total}</div>
      <div className={b('totals')}>
        <div className={b('totals-item')}>
          <div className={b('totals-head')}>{`${locale.total} ${locale.deposit}`}</div>
          <div className={b('totals-data')}>
            <div className={b('total-details')}>
              {formatNumberWithLimit(paymentHistory.details.totalDeposit)}
            </div>
            <span className={b('total-details-currency')}>
              ARS
            </span>
          </div>
        </div>
        <div className={b('totals-item')}>
          <div className={b('totals-head')}>{`${locale.total} ${locale.withdrawal}`}</div>
          <div className={b('totals-data')}>
            <div className={b('total-details')}>
              {formatNumberWithLimit(paymentHistory.details.totalWithdraw)}
            </div>
            <span className={b('total-details-currency')}>
              ARS
            </span>
          </div>
        </div>
      </div>
      <div className={b('header')}>
        <span className={b('header-left')}>{locale.result}:</span>
        <div className={b('page-count')}>
          <div className={b('page-count-title')}>{locale.elementsOnPage}:</div>
          <div className={b('page-count-select')}>
            <Select paginator items={itemsOnPageFilters} activeItem={itemsOnPage} onChange={selectItemChangedHandler} />
          </div>
        </div>
      </div>
      <div className={b('table')}>
        <TableHead locale={locale} />
        <div className={b('tbody')}>
          {!!rows.length ? rows : <div className={b('no-results')}>{locale.noResults}</div>}
        </div>
        <div className={b('paginator-wrapper')}>
          <div className={b('extra-info')}>
            {paymentHistory.details.totalCount} {locale.records}
          </div>
          <Paginator count={totalCount} currentPage={currentPage} onPageClick={onPageClick} itemsOnPage={itemsOnPage} />
        </div>
      </div>
    </div>
  );
};

Table.propTypes = {
  count: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onItemsOnPageChanged: PropTypes.func,
  onPageClick: PropTypes.func.isRequired,
};

export default Table;
