import React from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Spinner from 'components/Spinner/view/desktop';
import SVG from 'components/SVG';
import Button from 'components/Button';
import CopyToClipboard from 'components/CopyToClipboard';
// import InputDate from 'components/InputDate/desktop';
import PhoneInput from 'components/PhoneInput/PhoneInput';
import { phoneCodes } from 'components/PhoneInput/PhoneInput';

import { useInformationModel } from 'features/users/hooks';

import { formatNumberWithLimit } from 'shared/helpers/formatNumber';

import InformationTree from './InformationTree';
import { userTypes } from '../../../../data';

import './Information.scss';
import dayjs from 'dayjs';

const b = block('information');

const Information = () => {
  const { userId } = useParams();

  const {
    usersList: { totalCount },
  } = useSelector(state => state.users);
  const locale = useSelector(state => state.locale.locale);
  const auth = useSelector(state => state.auth);

  const history = useHistory();

  const { userInfo, agentInfo, isLoaded, actionProcessing } = useInformationModel();

  const isAgent = (Number(userInfo?.role) === 6 || Number(userInfo?.role) === 1) && auth?.id !== Number(userId);

  const phoneNumber = userInfo?.user?.phone;
  const birthDate = userInfo?.user?.birth_date && dayjs(userInfo?.user?.birth_date)?.format('DD.MM.YYYY');
  const modifiedPhoneNumber = phoneNumber?.replace('+54', '');
  const firstVisit = dayjs(userInfo?.user?.first_visit)?.format('DD.MM.YYYY');

  if (actionProcessing) return <Spinner isLoading={actionProcessing} />;

  return (
    <div className={b()}>
      <div className={b('wrapper')}>
        <div className={b('top')}>
          <div className={b('header')}>{locale.information}</div>

          {isLoaded && (
            <>
              <div className={b('user-block')}>
                <div className={b('user-block-left')}>
                  <SVG svgProps={{ svg: userTypes[userInfo.role].icon }} className={b('user-block-icon')} />
                  <div className={b('user-block-info')}>
                    <div className={b('username-block')}>
                      <span>{userInfo.username}</span>

                      <CopyToClipboard text={userInfo.username} />
                    </div>
                    <div className={b('user-block-info-type', { role: userInfo.role })}>
                      {userTypes[userInfo.role].type}
                    </div>
                  </div>
                </div>
                <div className={b('user-block-right')}>
                  <span>ID:</span>
                  <div className={b('id-block')}>
                    <span className={b('id')}>{userInfo.id}</span>
                    <CopyToClipboard text={userInfo.id} />
                  </div>
                </div>
                <div className={b('user-block-right')}>
                  <span>{locale.balance}:</span>
                  <span>{formatNumberWithLimit(userInfo.balance)}</span>
                </div>
              </div>

              <div className={b('block-wrapper')}>
                <div className={b('block')}>
                  <div className={b('block-title')}>{locale.creationDate}</div>
                  <div className={b('block-value')}>{firstVisit}</div>
                </div>

                <div className={b('block')}>
                  <div className={b('block-title')}>{locale.modificationDate}</div>
                  <div className={b('block-value')}>{userInfo.lastVisit}</div>
                </div>

                {isAgent && (
                  <>
                    <div className={b('block')}>
                      <div className={b('block-title')}>{locale.username}</div>
                      <div className={b('block-value')}>{userInfo.username}</div>
                    </div>

                    <div className={b('block')}>
                      <div className={b('block-title')}>{locale.name}</div>
                      <div className={b('block-value')}>{userInfo.name}</div>
                    </div>

                    <div className={b('block')}>
                      <div className={b('block-title')}>{locale.email}</div>
                      <div className={b('block-value')}>{userInfo.email}</div>
                    </div>
                  </>
                )}



                {!isAgent && (
                  <div className={b('block')}>
                    <div className={b('block-title')}>{locale.nameAndSurname}</div>
                    <div className={b('block-value')}>{`${userInfo.name} ${userInfo.surname}`}</div>
                  </div>
                )}

                {isAgent ? (
                  <div className={b('block')}>
                    <div className={b('block-title')}>{locale.surname}</div>
                    <div className={b('block-value')}>{userInfo.surname}</div>
                  </div>
                ) : (
                  <>
                    <div className={b('block')}>
                      <div className={b('block-title')}>{locale.username}</div>
                      <PhoneInput
                        // changePhoneNumber={changePhoneNumber}
                        readonly
                        phone={modifiedPhoneNumber}
                        countryCode={phoneCodes?.ARGENTINA?.code}
                      />
                    </div>
                    <div className={b('block')}>
                      <div className={b('block-title')}>{locale.dob}</div>
                      <div className={b('block-value')}>{birthDate}</div>
                    </div>
                  </>
                )}

                <div className={b('block')}>
                  <div className={b('block-title')}>{locale.currency}</div>
                  <div className={b('block-value')}>{userInfo.currency}</div>
                </div>

                {userInfo.role === '6' && (
                  <div className={b('block')}>
                    <div className={b('block-title')}>{locale.playersCount}</div>
                    <div className={b('block-value')}>{totalCount}</div>
                  </div>
                )}
              </div>

              <div className={b('info')}>
                <p className={b('info-title')}>{locale.parent}</p>
                <div className={b('user-block')}>
                  <div className={b('user-block-left')}>
                    <SVG svgProps={{ svg: userTypes[6].icon }} className={b('user-block-icon')} />
                    <div className={b('user-block-info')}>
                      <span>{agentInfo.username}</span>
                      <div className={b('user-block-info-type', { role: 1 })}>{userTypes[6].type}</div>
                    </div>
                  </div>
                  <div className={b('user-block-right')}>
                    <span>ID:</span>
                    <div className={b('parent-id-block')}>
                      <span>{agentInfo.id}</span>
                      <CopyToClipboard text={agentInfo.id} />
                    </div>
                  </div>
                  <div className={b('user-block-right')}>
                    <span>{locale.balance}:</span>
                    <span>{formatNumberWithLimit(agentInfo.balance)}</span>
                  </div>
                </div>
              </div>

              {userInfo.role !== '0' && userInfo.role !== '1' && (
                <div className={b('tree')}>
                  <p className={b('info-title')}>{locale.userTree}</p>
                  <InformationTree />
                </div>
              )}
              
            </>
          )}
        </div>
        <div className={b('bottom')}>
          <Button color="full-transparent" callBack={history.goBack} text={locale.close} size="low" />
        </div>
      </div>
    </div >
  );
};

export default Information;
