import React, { useMemo, useCallback } from 'react';
import block from 'bem-cn';
import PT from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import SVGInline from 'react-svg-inline';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { actions as authActions } from 'features/Auth';

import { formatNumberWithLimit } from 'shared/helpers/formatNumber';
import crossSVG from 'shared/img/cross.svg';

import './NotifiesView.scss';

const b = block('notifies-view');

dayjs.extend(customParseFormat);

const NotifiesView = ({ closeFunction }) => {
  const dispatch = useDispatch();
  const { notifications, agentsFromNotify } = useSelector(state => state.auth, shallowEqual);
  const { locale } = useSelector(state => state.locale, shallowEqual);

  const handleCrossClick = useCallback(
    id => {
      if (id) {
        dispatch(authActions.dropNotifications(id));
      } else {
        closeFunction();
      }
    },
    [closeFunction, dispatch],
  );

  const items = useMemo(
    () =>
      notifications?.map(({ id, date, type, amount, fromId, isNew }) => {
        const fromName = agentsFromNotify?.find(val => val.fromUserId === fromId)?.fromNick || `ID ${fromId}`;
        const defineRole = fromId === 5 || fromId === 6 ? locale.owner : locale.agent;

        return (
          <div className={b('item')} key={id}>
            <div className={b('item-wrapper')}>
              <div className={b('item-left')}>
                {isNew && <div className={b('new')}>{locale.new}</div>}
                <p className={b('type')}>{locale.paymentStatuses[type]}</p>
              </div>
              <div className={b('item-right')}>
                <p className={b('date')}>{dayjs(date, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY HH:mm')}</p>
                <SVGInline className={b('cross', { 'without-margin': true }).toString()} svg={crossSVG} onClick={() => handleCrossClick(id)} />
              </div>
            </div>
            <div className={b('item-wrapper')}>
              <p className={b('name')}>
                {`${
                  type === 0
                    ? locale.depositMessage(fromName, defineRole)
                    : locale.withdrawalMessage(fromName, defineRole)
                } `}
                <span className={b('amount')}>{formatNumberWithLimit(amount)}</span>
              </p>
            </div>
          </div>
        );
      }),
    [notifications, agentsFromNotify, locale, handleCrossClick],
  );

  return (
    <div className={b()}>
      <div className={b('header')}>
        <p className={b('title')}>{`${locale.notification}: ${notifications.length}`}</p>
        <SVGInline className={b('cross').toString()} svg={crossSVG} onClick={() => handleCrossClick()} />
      </div>

      <div className={b('items')}>{items}</div>
    </div>
  );
};

NotifiesView.propTypes = {
  closeFunction: PT.func,
};

export default NotifiesView;
