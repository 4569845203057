import React, { useState, useEffect, useCallback, useMemo } from 'react';
import block from 'bem-cn';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router';

import { actions } from 'features/users/redux';
import { actions as treeActions } from 'features/tree';
import Select from 'components/Select/desktop';

import UsersFilterBlock from './UsersFilterBlock';

import Table from './Table/Table';
import Tabs from './Tabs/Tabs';

import './Users.scss';

const b = block('users');

const Users = () => {
  const dispatch = useDispatch();

  const [itemsOnPage, setItemsOnPage] = useState({ value: 10, name: '10' });
  const [actionProcessing, changeActionProcessing] = useState(false);
  const [filterValue, onChangeFilterValue] = useState('');
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);


  const { tab } = useParams();

  const auth = useSelector(state => state.auth);
  const { isNeedToRefresh } = useSelector(state => state.users);
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const { selectedAgentId } = useSelector(state => state.tree);

  const itemsOnPageFilters = [
    { value: 10, name: '10' },
    { value: 25, name: '25' },
    { value: 50, name: '50' },
    { value: 100, name: '100' },
  ];

  const { email, id } = auth;

  const role = useMemo(() => {
    if (tab === 'players') {
      return 0;
    }
    if (tab === 'agents') {
      return 6;
    }
    if (tab === 'cashiers') {
      return 1;
    }
    if (tab === 'all' || tab === 'disabled') {
      return '';
    }
    setPage(0);
  }, [tab]);

  const isBanned = useMemo(() => {
    if (tab === 'disabled') {
      return true;
    } else return false;
  }, [tab]);


  const getUsers = useCallback(data => {
    return dispatch(actions.getUsersList(data));
  }, [dispatch]);


  const selectItemChangedHandler = itemValue => {
    setItemsOnPage({ value: itemValue, name: itemValue });
    onItemsOnPageChanged(itemValue);
  };

  const selectAgent = useCallback(
    id => {
      dispatch(treeActions.selectAgentId(id));
    },
    [dispatch],
  );

  const getTreeById = name => {
    changeActionProcessing(true);
    getUsers({ username: name ?? '' })
      .then(data => {
        const id = data?.parentId;
        if (id) {
          dispatch(treeActions.getUsersByUserId({ id, nesting: [id], name }, true)).finally(() => {
            selectAgent(id);
            changeActionProcessing(false);
          });
        }
      })
      .catch(error => {
        console.error("Error during user search:", error);
        changeActionProcessing(false);
      });
  };

  const changeFilterValue = useCallback(value => {
    onChangeFilterValue(value.toLowerCase());
    changeActionProcessing(false);
  }, []);

  const onItemsOnPageChanged = newItemsOnPage => {
    setCount(newItemsOnPage);
    setPage(0);
  };

  useEffect(() => {
    getUsers({ username: '', role, userId: selectedAgentId || id, isBanned, count, page, tab });
  }, [dispatch, email, role, id, isBanned, count, page, isNeedToRefresh, selectedAgentId, tab, getUsers]);


  useEffect(() => {
    setPage(0);
  }, [selectedAgentId]);

  return (
    <div className={b()}>
      <div className={b('center')}>
        <Tabs role={auth.role} />
        <div className={b('center-controller')}>
          <UsersFilterBlock
            callBack={() => getTreeById(filterValue ?? '')}
            actionProcessing={actionProcessing}
            filterValue={filterValue}
            changeFilterValue={changeFilterValue}
          />
          <div className={b('page-count')}>
            <div className={b('page-count-title')}>{locale.recordsOnPage}:</div>
            <div className={b('page-count-select')}>
              <Select
                paginator
                items={itemsOnPageFilters}
                activeItem={itemsOnPage}
                onChange={selectItemChangedHandler}
              />
            </div>
          </div>
        </div>
      </div>
      <Table
        filterValue={filterValue}
        locale={locale}
        currentPage={page}
        onPageClick={setPage}
        itemsOnPage={itemsOnPage}
      />
    </div>
  );
};

export default Users;
