/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';

import SVG from 'components/SVG';
import Switcher from 'components/Switcher';

import { actions } from 'features/users/redux';
import detailsSVG from 'features/users/img/details.svg';
import dropdownSVG from 'features/users/img/dropdown.svg';

import { permissionsDetailsData } from '../../../../../data';

import './PermissionsDetailsItems.scss';

const b = block('permissions-details-items-desktop ');

const PermissionsDetailsItems = ({ item, userId, userInfo, permissionsForAgent, setPermissionsForAgent }) => {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const { bannedProviders } = useSelector(state => state.users);

  const [categoryOpen, setCategoryOpen] = useState(
    permissionsDetailsData.reduce((acc, curr) => ((acc[curr.category] = false), acc), {}),
  );

  const checkIsCategoryBlocked = useCallback(category => {
    const categoryItem = bannedProviders?.lockData?.find(item => item.name === category);
    return categoryItem
      ? categoryItem.isOnlyPlayers || categoryItem.isFullBranch || (!categoryItem.isOnlyPlayers && !categoryItem.isFullBranch)
      : false;
  }, [bannedProviders]);

  const checkIsProviderBanned = useCallback(provider => {
    const providerItem = bannedProviders?.blocklist?.find(item => item.name === provider);
    return providerItem
      ? providerItem.isOnlyPlayers || providerItem.isFullBranch || (!providerItem.isOnlyPlayers && !providerItem.isFullBranch)
      : false;
  }, [bannedProviders]);

  const findCategory = item =>
    permissionsDetailsData.filter(categoryItem => categoryItem.items.includes(item))[0].category;

  const handleSetProviderState = data => dispatch(actions.setBannedProviders({ data }));

  const getIsFullBranch = useCallback((target, isCategory = false) => {
    if (userInfo.role === '0') return false;
    return isCategory
      ? bannedProviders?.lockData?.find(item => item.name === target)?.isFullBranch || true
      : bannedProviders?.lockData?.find(item => item.name === target)?.isFullBranch || true;
  }, [bannedProviders]);

  const getIsOnlyPlayers = useCallback((target, isCategory = false) => {
    if (userInfo.role === '0') return true;
    return isCategory
      ? bannedProviders?.blocklist?.find(item => item.name === target)?.isOnlyPlayers || true
      : bannedProviders?.blocklist?.find(item => item.name === target)?.isOnlyPlayers || true;
  }, [bannedProviders]);

  return (
    <div className={b()} key={item.category}>
      <div className={b('item')}>
        <div className={b('item-container')}  onClick={() => setCategoryOpen({ ...categoryOpen, [item.category]: !categoryOpen[item.category] })}>
          <SVG
            onClick={() => setCategoryOpen({ ...categoryOpen, [item.category]: !categoryOpen[item.category] })}
            containerClassName={b('item-dropdown')}
            svgProps={{ svg: dropdownSVG }}
          />
          <div className={b('item-title')}>{locale[item.categoryName]}</div>
        </div>
        <div className={b('item-container')}>
          <div className={b('item-switcher')}>
            <Switcher
              isActive={!checkIsCategoryBlocked(item.category)}
              onSwitch={() =>
                handleSetProviderState({
                  providers: item.items,
                  action: checkIsCategoryBlocked(item.category) ? 'remove' : 'add',
                  isFullBranch: getIsFullBranch(item.category, true),
                  isFullCategory: !checkIsCategoryBlocked(item.category),
                  isOnlyPlayers: getIsOnlyPlayers(item.category, true),
                  id: userId,
                  category: item.category,
                })
              }
            />
          </div>
          <>
            {userInfo.role !== '0' && (
              <SVG
                onClick={() =>
                  setPermissionsForAgent({
                    ...permissionsForAgent,
                    isOpen: true,
                    category: item.category,
                    enabled: !checkIsCategoryBlocked(item.category),
                    items: item.items,
                    isChildItem: false,
                  })
                }
                containerClassName={b('item-details')}
                svgProps={{ svg: detailsSVG }}
              />
            )}
          </>
        </div>
      </div>
      {categoryOpen[item.category] &&
        item.items.map((item, index) => {
          return (
            <div key={index} className={b('item', { inner: true })}>
              <div className={b('item-container')}>
                <div className={b('item-title', { inner: true })}>{item.toLowerCase()}</div>
              </div>
              <div className={b('item-container')}>
                <div className={b('item-switcher')}>
                  <Switcher
                    isActive={!checkIsProviderBanned(item)}
                    onSwitch={() =>
                      handleSetProviderState({
                        providers: [item],
                        action: checkIsProviderBanned(item) ? 'remove' : 'add',
                        isFullBranch: getIsFullBranch(item),
                        isFullCategory: false,
                        isOnlyPlayers: getIsOnlyPlayers(item),
                        id: userId,
                        category: findCategory(item),
                      })
                    }
                  />
                </div>
                {userInfo.role !== '0' && (
                  <SVG
                    onClick={() =>
                      setPermissionsForAgent({
                        ...permissionsForAgent,
                        isOpen: true,
                        category: findCategory(item),
                        enabled: !checkIsProviderBanned(item),
                        items: [item],
                        isChildItem: true,
                      })
                    }
                    containerClassName={b('item-details')}
                    svgProps={{ svg: detailsSVG }}
                  />
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default PermissionsDetailsItems;
