import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import block from 'bem-cn';

import FilterBlock from './FilterBlock';
import ProfileBlock from './ProfileBlock';

import Table from './Table/Table';

import './MyDebtAccount.scss';

const b = block('my-debt-account');

const MyDebtAccount = () => {
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);
  const locale = useSelector(state => state.locale.locale);
  const financeHistory = useSelector(state => state.finance.financeHistory, shallowEqual);

  const onItemsOnPageChanged = newItemsOnPage => {
    setCount(newItemsOnPage);
    setPage(0);
  };
  return (
    <div className={b()}>
      <ProfileBlock />
      <div className={b('header')}>{locale.myOperations}</div>
      <FilterBlock count={count} currentPage={page} onPageClick={setPage} />
      <Table
        currentPage={page}
        onPageClick={setPage}
        onItemsOnPageChanged={onItemsOnPageChanged}
        thTdItems={financeHistory.transfers}
        totalCount={financeHistory.details.totalCount}
      />
    </div>
  );
};

export default MyDebtAccount;
