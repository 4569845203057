import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { actions } from 'features/users/redux';
import { actions as treeActions } from 'features/tree';

const useTree = (isMobile = false) => {
  const dispatch = useDispatch();
  const { selectedAgentId } = useSelector(state => state.tree, shallowEqual);

  const selectAgent = useCallback(
    id => {
      dispatch(treeActions.selectAgentId(id));
    },
    [dispatch],
  );

  const getUsers = data => {
    return dispatch(actions.getUsersList(data));
  };

  const getTreeById = name => {
    const data = name.length ? { username: name } : { userId: selectedAgentId };
    
    return getUsers(data).then(data => {
      if (data?.parentId) {
        dispatch(treeActions.getUsersByUserId({ id: data.parentId, nesting: [data.parentId], name }, true)).finally(
          () => {
            if (!isMobile) selectAgent(data.parentId);
          },
        );
        return data?.upperParent;
      }
    });
  };

  return { getTreeById };
};

export default useTree;
