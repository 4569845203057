import React, { useState, useCallback, useContext, useMemo } from 'react';
import block from 'bem-cn';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ModalContext } from 'components/ModalProvider/ModalProvider';
import Button from 'components/Button';
import Input from 'components/Input';
import Switcher from 'components/Switcher';
import { actions } from 'features/users/redux';
import { validatePassword } from 'shared/helpers/validatePassword';
import './CreateAgent.scss';

const b = block('create-agent');

const CreateAgent = () => {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const history = useHistory();
  const { openModal, closeModal } = useContext(ModalContext);
  const { passwordSettings: { agent } } = useSelector(state => state.userSettings);

  const [form, setForm] = useState({
    username: '',
    password: '',
    confirmPassword: '',
    email: '',
    name: '',
    surname: '',
  });
  const [withdrawalAllowed, setWithdrawalAllowed] = useState(true);
  const [depositAllowed, setDepositAllowed] = useState(true);
  const [canCreateOnlyPlayers, setCanCreateOnlyPlayers] = useState(false);

  const isNewPasswordValid = useMemo(() => {
    return validatePassword(form.password, agent) && form.password !== '';
  }, [form.password, agent]);

  const isConfirmPasswordValid = useMemo(() => {
    return validatePassword(form.confirmPassword, agent)
      && form.confirmPassword === form.password;
  }, [form.confirmPassword, form.password, agent]);

  const onSubmit = useCallback(() => {
    closeModal();
    dispatch(
      actions.createAgent({
        form: { ...form, withdrawalAllowed, depositAllowed, canCreateOnlyPlayers },
        callback: () => history.push('/users/all'),
      }),
    );
  }, [closeModal, dispatch, form, withdrawalAllowed, depositAllowed, canCreateOnlyPlayers, history]);

  const openPopUp = useCallback(
    e => {
      e.preventDefault();
      openModal({
        content: (
          <div className={b('confirmation-pop-up')}>
            <div
              className={b('confirmation-pop-up', 'description')}
              dangerouslySetInnerHTML={{
                __html: `${locale.wantToCreateAgent} <span>${form.username}</span>.`,
              }}
            />
            <div className={b('confirmation-pop-up', 'buttons')}>
              <Button color="transparent" size="low" callBack={onSubmit} text={locale.createAgent} />
              <Button callBack={closeModal} text={locale.cancel} size="low" />
            </div>
          </div>
        ),
      });
    },
    [openModal, locale.wantToCreateAgent, locale.createAgent, locale.cancel, form.username, onSubmit, closeModal],
  );

  const changeValue = useCallback(
    e => setForm({ ...form, [e.currentTarget.name]: e.currentTarget.value?.replace(/\s/g, '') }),
    [form],
  );

  const changeValueUsername = useCallback(
    e => setForm({ ...form, username: e.currentTarget.value?.replace(/\s/g, '') }),
    [form],
  );

  const disabled = !(isNewPasswordValid && isConfirmPasswordValid) || form.username.length === 0;

  return (
    <div className={b()}>
      <form className={b('form')} onSubmit={openPopUp} autoComplete="off">
        <div className={b('header')}>{locale.createAgent}</div>
        <div className={b('fields')}>
          <div className={b('form', 'input-block')}>
            <div className={b('input')}>
              <span className={b('form', 'input-block-title')}>{locale.username}</span>
              <Input
                validState={form.username.length > 0 ? 1 : 3}
                placeholder={locale.username}
                onChange={changeValueUsername}
                value={form.username}
              />
            </div>
          </div>
          <div className={b('form', 'input-block')}>
            <div className={b('input')}>
              <span className={b('form', 'input-block-title')}>{locale.email}</span>
              <Input placeholder={locale.email} onChange={changeValue} value={form.email} name="email" />
            </div>
          </div>
          <div className={b('form', 'input-block')}>
            <span className={b('form', 'input-block-title')}>{locale.password}</span>
            <div className={b('input')}>
              <Input
                placeholder={locale.password}
                withEye
                validState={isNewPasswordValid ? 1 : 3}
                type="password"
                onChange={changeValue}
                value={form.password}
                name="password"
                autocomplete="new-password"
              />
            </div>
          </div>
          <div className={b('form', 'input-block')}>
            <span className={b('form', 'input-block-title')}>{locale.name}</span>
            <div className={b('input')}>
              <Input placeholder={locale.name} onChange={changeValue} value={form.name} name="name" />
            </div>
          </div>
          <div className={b('form', 'input-block')}>
            <span className={b('form', 'input-block-title')}>{locale.confirmPassword}</span>
            <div className={b('input')}>
              <Input
                placeholder={locale.confirmPassword}
                onChange={changeValue}
                value={form.confirmPassword}
                name="confirmPassword"
                withEye
                type="password"
                autocomplete="new-password"
                validState={isConfirmPasswordValid ? 3 : 1}
              />
            </div>
          </div>
          <div className={b('form', 'input-block')}>
            <span className={b('form', 'input-block-title')}>{locale.surname}</span>
            <div className={b('input')}>
              <Input placeholder={locale.surname} onChange={changeValue} value={form.surname} name="surname" />
            </div>
          </div>
        </div>

        <div className={b('form', 'switchers')}>
          <div className={b('form', 'switchers-block')}>
            <div
              className={b('form', 'switchers-title')}
              dangerouslySetInnerHTML={{ __html: locale.withdrawalAllowed }}
            />
            <div className={b('form', 'switchers-item')}>
              <Switcher isActive={withdrawalAllowed} onSwitch={setWithdrawalAllowed} />
            </div>
          </div>
          <div className={b('form', 'switchers-block')}>
            <div className={b('form', 'switchers-title')} dangerouslySetInnerHTML={{ __html: locale.depositAllowed }} />
            <div className={b('form', 'switchers-item')}>
              <Switcher isActive={depositAllowed} onSwitch={setDepositAllowed} />
            </div>
          </div>
          <div className={b('form', 'switchers-block')}>
            <div className={b('form', 'switchers-title')} dangerouslySetInnerHTML={{ __html: locale.canCreateOnlyPlayers }} />
            <div className={b('form', 'switchers-item')}>
              <Switcher isActive={canCreateOnlyPlayers} onSwitch={setCanCreateOnlyPlayers} />
            </div>
          </div>
        </div>

        <div className={b('bottom')}>
          <Button color="full-transparent" callBack={history.goBack} text={locale.cancel} size="low" />
          <Button disabled={disabled} type="submit" size="low" text={locale.createAgent} />
        </div>
      </form>
    </div>
  );
};

export default CreateAgent;
