import allUsers from './img/allUsers.svg';
import agents from './img/agents.svg';
import cashier from './img/cashier.svg';
import players from './img/players.svg';
import createAgent from './img/createAgent.svg';
import createPlayer from './img/createPlayer.svg';
import generalReport from './img/generalReport.svg';
import depositsAndWithdrawals from './img/depositsAndWithdrawals.svg';
import playerHistory from './img/playerHistory.svg';
import playerAndAgentDetail from './img/playerAndAgentDetail.svg';
import sportBets from './img/sportBets.svg';
import myDebtAccount from './img/myDebtAccount.svg';
import howToStart from './img/howToStart.svg';

import allUsersWhite from './img/white/allUsers.svg';
import agentsWhite from './img/white/agents.svg';
import playersWhite from './img/white/players.svg';
import createAgentWhite from './img/white/createAgent.svg';
import createPlayerWhite from './img/white/createPlayer.svg';
import generalReportWhite from './img/white/generalReport.svg';
import depositsAndWithdrawalsWhite from './img/white/depositsAndWithdrawals.svg';
import playerHistoryWhite from './img/white/playerHistory.svg';
import playerAndAgentDetailWhite from './img/white/playerAndAgentDetail.svg';
import sportBetsWhite from './img/white/sportBets.svg';
import myDebtAccountWhite from './img/white/myDebtAccount.svg';
import howToStartWhite from './img/white/howToStart.svg';

export const sidebarMenu = [
  {
    title: 'users',
    route: '#',
    routeForActiveTab: '',
    level: 0,
    children: [
      {
        title: 'allUsers',
        route: '/users/all',
        routeForActiveTab: '/users/all',
        img: allUsers,
        level: 1,
        whiteImg: allUsersWhite,
      },
      {
        title: 'agents',
        route: '/users/agents',
        routeForActiveTab: '/users/agents',
        img: agents,
        level: 1,
        whiteImg: agentsWhite,
        access: 6 // только для агента
      },
      {
        title: 'cashierAgent',
        route: '/users/cashiers',
        routeForActiveTab: '/users/cashiers',
        img: cashier,
        level: 1,
        whiteImg: agentsWhite,
        access: 6 // только для агента
      },
      {
        title: 'players',
        route: '/users/players',
        routeForActiveTab: '/users/players',
        img: players,
        level: 1,
        whiteImg: playersWhite,
        access: 6 // только для агента
      },
      {
        title: 'createAgent',
        route: '/user/create-agent',
        routeForActiveTab: '/user/create-agent',
        img: createAgent,
        level: 1,
        whiteImg: createAgentWhite,
        access: 6 // только для агента
      },
      {
        title: 'createPlayer',
        route: '/user/create-player',
        routeForActiveTab: '/user/create-player',
        img: createPlayer,
        level: 1,
        whiteImg: createPlayerWhite,
      },
    ],
  },
  {
    title: 'financialReports',
    route: '#',
    routeForActiveTab: '',
    level: 0,
    children: [
      {
        title: 'generalReport',
        img: generalReport,
        route: '/reports/financial-reports/general',
        routeForActiveTab: '/reports/financial-reports/general',
        level: 1,
        whiteImg: generalReportWhite,
      },
      {
        title: 'depositsAndWithdrawals',
        img: depositsAndWithdrawals,
        route: '/reports/financial-reports/cash',
        routeForActiveTab: '/reports/financial-reports/cash',
        level: 1,
        whiteImg: depositsAndWithdrawalsWhite,
      },
    ],
  },
  {
    title: 'playerReports',
    route: '#',
    routeForActiveTab: '',
    level: 0,
    children: [
      {
        title: 'playerHistory',
        img: playerHistory,
        route: '/reports/player-reports/player-history',
        routeForActiveTab: '/reports/player-reports/player-history',
        level: 1,
        whiteImg: playerHistoryWhite,
      },
      {
        title: 'playerAndAgentDetail',
        img: playerAndAgentDetail,
        route: '/reports/player-reports/detail-by-player',
        routeForActiveTab: '/reports/player-reports/detail-by-player',
        level: 1,
        whiteImg: playerAndAgentDetailWhite,
      },
      {
        title: 'sportBets',
        img: sportBets,
        route: '/reports/player-reports/sport-bets',
        routeForActiveTab: '/reports/player-reports/sport-bets',
        level: 1,
        whiteImg: sportBetsWhite,
      },
    ],
  },
  {
    title: 'finance',
    route: '#',
    routeForActiveTab: '',
    level: 0,
    children: [
      {
        title: 'myDebtAccount',
        img: myDebtAccount,
        route: '/finance/my-debt-account',
        routeForActiveTab: '/finance/my-debt-account',
        level: 1,
        whiteImg: myDebtAccountWhite,
      },
      {
        title: 'howToStart',
        img: howToStart,
        route: '/finance/how-to-start',
        routeForActiveTab: '/finance/how-to-start',
        level: 1,
        whiteImg: howToStartWhite,
      },
    ],
  },
];

export const agentsItem = {
  title: 'agents',
  routeForActiveTab: '',
  img: allUsers,
  level: 0,
};
