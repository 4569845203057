import React from 'react';
import asyncPoll from 'react-async-poll';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as authActions } from '../../features/Auth';

const AsyncPollNotifications = () => null;

function mapStateToProps(state) {
  return {
    isAuth: state.auth.isAuth,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    getNotifications: authActions.getNotifications,
  };
  return bindActionCreators(actions, dispatch);
}

function onPollInterval(props) {
  if (props.isAuth) {
    props.getNotifications();
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(asyncPoll(20 * 1000, onPollInterval)(AsyncPollNotifications));
