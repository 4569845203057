/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import block from 'bem-cn';

import Switcher from 'components/Switcher';

import './PermissionsDetailsForAgent.scss';

const b = block('permissions-details-for-agent-desktop');

const PermissionsDetailsForAgent = ({ permissionsForAgent, setPermissionsForAgent }) => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const { bannedProviders } = useSelector(state => state.users, shallowEqual);

  useEffect(() => {
    setPermissionsForAgent({
      ...permissionsForAgent,
      showSelectedForUser: true,
      permissionsToChildUsers: true,
    });
    if(!permissionsForAgent.isChildItem) {
      const currentPermission = bannedProviders?.lockData.find(item => item.name === permissionsForAgent.category);
      if (currentPermission) {
        setPermissionsForAgent({
          ...permissionsForAgent,
          showSelectedForUser: currentPermission.isOnlyPlayers,
          permissionsToChildUsers: currentPermission.isFullBranch,
        })
      }
    } else {
      const currentPermission = bannedProviders?.blocklist.find(item => item.name === permissionsForAgent.items[0]);
      if (currentPermission) {
        setPermissionsForAgent({
          ...permissionsForAgent,
          showSelectedForUser: currentPermission.isOnlyPlayers,
          permissionsToChildUsers: currentPermission.isFullBranch,
        })
      }
    }
  }, []);

  return (
    <div className={b()}>
      <div className={b('header-info')}>
        <div className={b('header-info-title', { fullWidth: true })}>
          {locale.hideTheSelectedCategoryForUser}
          <div className={b('header-info-switcher', { noGap: true })}>
            <Switcher
              isActive={permissionsForAgent.showSelectedForUser}
              onSwitch={() =>
                setPermissionsForAgent({
                  ...permissionsForAgent,
                  showSelectedForUser: !permissionsForAgent.showSelectedForUser,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className={b('header-info')}>
        <div className={b('header-info-title', { fullWidth: true })}>
          {locale.copyPermissions}
          <div className={b('header-info-switcher', { noGap: true })}>
            <Switcher
              isActive={permissionsForAgent.permissionsToChildUsers}
              onSwitch={() =>
                setPermissionsForAgent({
                  ...permissionsForAgent,
                  permissionsToChildUsers: !permissionsForAgent.permissionsToChildUsers,
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PermissionsDetailsForAgent;
