import BaseApi from '../BaseApi';
import { ReportsApiConverter } from './ReportsApiConverter';

class ReportsApi extends BaseApi {
  constructor(baseUrl) {
    super(baseUrl);
    this.baseUrl = `${baseUrl}/api/agent_admin/user`;
    this.converter = new ReportsApiConverter();
  }

  getStatisticProvider = ({ id, requestedBody }) =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/${id}/statistic/provider/`,
      requestedBody,
      null,
      this.converter.convertStatisticProvider,
    );

  getPaymentHistory = ({ id, requestedBody }) =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/${id}/payment/history/`,
      requestedBody,
      null,
      this.converter.convertPaymentHistory,
    );

  getStatisticPlayer = ({ id, requestedBody }) =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/${id}/statistic/player/`,
      requestedBody,
      null,
      this.converter.convertStatisticPlayer,
    );

  getSlotsHistory = ({ id, requestedBody }) =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/${id}/slots/history/`,
      requestedBody,
      null,
      this.converter.convertSlotsHistory,
    );

  getBetsHistory = ({ id, requestedBody }) =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/sport/coupon/`,
      requestedBody,
      null,
      this.converter.convertBetsHistory,
    );

  getDetailsBetHistory = (id) =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/sport/coupon/${id}/bets/`,
      null,
      null,
      this.converter.convertDetailsBetsHistory,
    );

  getFinanceHistory = ({ id, requestedBody }) =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/${id}/finance/history/`,
      requestedBody,
      null,
      this.converter.convertFinanceHistory,
    );
}

export default ReportsApi;
