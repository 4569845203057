import { addNotify } from 'features/notify';

import { actions } from 'features/Auth';
import dayjs from 'dayjs';

const actionTypes = {
  ACTION_PROCESSING: 'user/ACTION_PROCESSING',
  CREATE_USER_SUCCESS: 'user/CREATE_USER_SUCCESS',
  GET_USERS_LIST_SUCCESS: 'user/GET_USER_LIST_SUCCESS',
  RESET_PASSWORD_SUCCESS: 'user/RESET_PASSWORD_SUCCESS',
  GET_USER_INFO_SUCCESS: 'user/GET_USER_INFO_SUCCESS',
  USER_INFO_DELETE: 'user/USER_INFO_DELETE',
  DATA_REFRESH: 'user/DATA_REFRESH',
  GET_BANNED_PROVIDERS: 'user/GET_BANNER_PROVIDERS',
  SET_BANNED_PROVIDERS: 'user/SET_BANNER_PROVIDERS',
};

function createUser({ form, callback }) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const { locale } = getState().locale;
    const { username, password, phone, nameAndSurname, birth_date } = form;

    function splitNameSurname(input) {
      const words = input.split(' ');
      const name = words[0];
      const surname = words[1];

      return { name, surname };
    }

    const { name, surname } = splitNameSurname(nameAndSurname);

    const requestBody = {
      username,
      birth_date: birth_date ? dayjs(birth_date).format('YYYY-MM-DD') : null,
      first_name: name || null,
      last_name: surname || null,
      password,
      phone: phone ? `+54${phone}` : null,
      role: 0, // роль для создания юзера
    };

    if (requestBody.password.length < 1 || requestBody.username.length < 1) {
      dispatch(addNotify('Password and username shoud not be empty', 'error'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    } else {
      const response = await api.user.createUser(requestBody);

      if (response.success) {
        dispatch(addNotify(locale.createSuccess, 'success'));
        dispatch({ type: actionTypes.CREATE_USER_SUCCESS });
        dispatch({ type: actionTypes.DATA_REFRESH, payload: true });
        callback && callback();
      } else {
        dispatch(addNotify(response.errorMessage, 'error'));
        dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
      }
    }
  };
}

function createAgent({ form, callback }) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const { locale } = getState().locale;
    const { username, password, email, name, surname, withdrawalAllowed, depositAllowed, canCreateOnlyPlayers } = form;

    const requestBody = {
      username,
      password,
      email,
      first_name: name,
      last_name: surname,
      is_withdrawal_access: withdrawalAllowed,
      is_deposit_access: depositAllowed,
      role: 6,
      can_create_only_players: canCreateOnlyPlayers
    };


    if (requestBody.password.length < 1 || requestBody.username.length < 1) {
      dispatch(addNotify('password and username shoud not be empty', 'error'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    } else {
      const response = await api.user.createUser(requestBody);
      if (response.success) {
        dispatch(addNotify(locale.createSuccess, 'success'));
        dispatch({ type: actionTypes.CREATE_USER_SUCCESS });
        dispatch({ type: actionTypes.DATA_REFRESH, payload: true });
        callback && callback();
      } else {
        dispatch(addNotify(response.errorMessage, 'error'));
        dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
      }
    }
  };
}

// type = auto | search
function getUsersList(params, type = 'auto') {
  return async (dispatch, getState, extra) => {
    const { api } = extra;

    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

    const response = type === 'auto' ? await api.user.getUsersList(params) : await api.user.searchUser(params);

    if (response.success) {
      const parentId = response.data?.users[0]?.id;
      const upperParent = response.data?.users[0]?.upperParent;

      dispatch({
        type: actionTypes.GET_USERS_LIST_SUCCESS,
        payload: response.data,
      });

      return { parentId, upperParent };
    } else {
      dispatch(addNotify(response.errorMessage, 'error'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    }
  };
}

function getUsersListForSearch(params) {
  return async (dispatch, getState, extra) => {
    const { api } = extra;

    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

    const response = await api.user.searchUser(params);

    if (response.success) {
      return response.data.users
    } else {
      dispatch(addNotify(response.errorMessage, 'error'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    }
  };
}

function changeBanState(id, value) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const { locale } = getState().locale;
    const response = await api.user.changeBanState(id, value);
    const text = value ? locale.banSuccess : locale.unbanSuccess;
    if (response.success) {
      dispatch(addNotify(text, 'success'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    } else {
      dispatch(addNotify('Error', 'error'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    }
  };
}

function resetPassword(id, callback, new_password) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const { locale } = getState().locale;
    const response = await api.user.resetPassword(id, new_password);
    if (response.success) {
      callback(true);
      dispatch(addNotify(locale.resetSuccess, 'success'));
      dispatch({
        type: actionTypes.RESET_PASSWORD_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch(addNotify('Error', 'error'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    }
  };
}

function changeUserBalance(id, amount, isOutcome, callback) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const { locale } = getState().locale;
    const response = await api.user.changeUserBalance(id, amount, isOutcome);
    if (response.success) {
      dispatch(getUsersList());
      callback('');
      dispatch(addNotify(isOutcome ? locale.withdrawalSuccess : locale.topUpSuccess, 'success'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    } else {
      dispatch(addNotify('Error', 'error'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    }
  };
}

function depositOrWithdrawal({ amount, operation, id, callback }) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const { locale } = getState().locale;
    const requestBody = {
      amount,
      operation, // 0 - deposit, 1 - withdrawal
      id,
    };

    if (requestBody.amount.length < 1) {
      dispatch(addNotify('amount shoud not be empty', 'error'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    } else {
      const response = await api.user.depositOrWithdrawal(requestBody);
      if (response.success) {
        dispatch(addNotify(locale.createSuccess, 'success'));
        dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
        callback && callback();
      } else {
        dispatch(addNotify(response.errorMessage, 'error'));
        dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
      }
    }
  };
}

function changePassword({ data, id, callback }) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const { locale } = getState().locale;

    if (data.password.length < 1) {
      dispatch(addNotify('password shoud not be empty', 'error'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    } else {
      const response = await api.user.changeUserInfo({ data, id });
      if (response.success) {
        dispatch(addNotify(locale.createSuccess, 'success'));
        dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
        dispatch(actions.checkAuth());
        callback && callback();
      } else {
        dispatch(addNotify(response.errorMessage, 'error'));
        dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
      }
    }
  };
}

function disableUser({ data, id, callback }) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const { locale } = getState().locale;

    const response = await api.user.changeUserInfo({ data, id });
    if (response.success) {
      dispatch(addNotify(locale.createSuccess, 'success'));
      dispatch({ type: actionTypes.DATA_REFRESH, payload: true });
      callback && callback();
    } else {
      dispatch(addNotify(response.errorMessage, 'error'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    }
  };
}

function modifyAgent({ data, id, callback }) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const { locale } = getState().locale;

    function splitNameSurname(input) {
      const words = input.split(' ');
      const name = words[0];
      const surname = words[1];
      return { name, surname };
    }

    const { name, surname } = splitNameSurname(data.nameAndSurname);

    const requestBody = {
      first_name: data.name || name,
      last_name: data.surname || surname,
      email: data.email,
      is_withdrawal_access: data.withdrawalAllowed,
      is_deposit_access: data.depositAllowed,
      birth_date: data.birthDate,
      phone: data.phone,

    };

    // if (data.email.length < 1 || data.name.length < 1 || data.surname.length < 1) {
    //   dispatch(addNotify('Fields shoud not be empty', 'error'));
    //   dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    // } else {
    const response = await api.user.changeUserInfo({ data: requestBody, id });
    if (response.success) {
      dispatch(addNotify(locale.createSuccess, 'success'));
      dispatch({ type: actionTypes.DATA_REFRESH, payload: true });
      callback && callback();
    } else {
      dispatch(addNotify(response.errorMessage, 'error'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    }
    // }
  };
}

function getUserInfo({ id, callback }) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;

    const response = await api.user.getUserInfo({ id });
    if (response.success) {
      dispatch({
        type: actionTypes.GET_USER_INFO_SUCCESS,
        payload: response.data,
      });
      callback && callback();
    } else {
      dispatch(addNotify(response.errorMessage, 'error'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    }
  };
}

function deleteUserInfo() {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    dispatch({
      type: actionTypes.USER_INFO_DELETE,
    });
  };
}

function getBannedProviders({ id, callback }) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;

    const response = await api.user.getBannedProviders({ id });

    if (response.success) {
      dispatch({
        type: actionTypes.GET_BANNED_PROVIDERS,
        payload: response.data,
      });
      callback && callback();
    } else {
      dispatch(addNotify(response.errorMessage, 'error'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    }
  };
}

function setBannedProviders({ data, callback }) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;

    const response = await api.user.setBannedProviders({ data });

    if (response.success) {
      dispatch({
        type: actionTypes.SET_BANNED_PROVIDERS,
        payload: response.data,
      });
      callback && callback();
    } else {
      dispatch(addNotify(response.errorMessage, 'error'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    }
  };
}

export {
  actionTypes,
  createUser,
  getUsersList,
  changeBanState,
  resetPassword,
  changeUserBalance,
  createAgent,
  depositOrWithdrawal,
  changePassword,
  disableUser,
  getUserInfo,
  deleteUserInfo,
  modifyAgent,
  getBannedProviders,
  setBannedProviders,
  getUsersListForSearch
};
