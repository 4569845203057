import React, { useCallback,useState } from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';

import './CountryCodeSelector.scss';

const CountryCodeSelector = ({ phoneCodes, activeValue, callBack, changeCurrency, currencies }) => {
  const [isOpen, setOpen] = useState(false);
  const b = block('country-code-selector');

  const onClick = useCallback(key => {
    callBack(key);
    const item = phoneCodes[key];
    if (item.appropriateCurrency) changeCurrency(currencies.find(t => t === item.appropriateCurrency) || '');
  }, []);

  const optionsList = Object.keys(phoneCodes).map(
    key =>
      activeValue !== key && (
        <div key={key} className={b('item')} onClick={() => onClick(key)}>
          <img className={b('image')} src={phoneCodes[key].img} alt="" />
          {phoneCodes[key].code}
        </div>
      ),
  );

  const changeOpened = () => setOpen(!isOpen);

  return (
    <div className={b()} onClick={changeOpened}>
      <div className={b('active-item', { opened: isOpen ? 'open' : 'close' })}>
        <img className={b('image')} src={phoneCodes[activeValue].img} alt="" />
        {phoneCodes[activeValue].code}
        <div className={b('line')} />
      </div>
      <div className={b('additional-items')}>{isOpen && optionsList}</div>
    </div>
  );
};

CountryCodeSelector.propTypes = {
  currencies: PropTypes.array.isRequired,
  phoneCodes: PropTypes.object.isRequired,
  activeValue: PropTypes.string.isRequired,
  callBack: PropTypes.func.isRequired,
  changeCurrency: PropTypes.func.isRequired,
};

export default CountryCodeSelector;
