import React from 'react';
import block from 'bem-cn';
import './TableHead.scss';

const TableHead = ({ locale }) => {
  const b = block('table-head-cash');

  return (
    <div className={b()}>
      <div className={b('table-head')}>{locale.date}</div>
      <div className={b('table-head')}>{locale.transactionType}</div>
      <div className={b('table-head')}>{locale.amount}</div>
      <div className={b('table-head')}>{locale.balanceBefore}</div>
      <div className={b('table-head')}>{locale.balanceAfter}</div>
    </div>
  );
};

export default TableHead;
