import React, { useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import arrowSvg from './img/arrow.svg';

import './ListItem.scss';

const b = block('list-item');

const ListItem = ({ item, changeMenuOpen, isParent = true }) => {
  const [isOpened, setOpened] = useState(item.children?.length ? true : null);

  const role = useSelector(state => state.auth.role);
  const locale = useSelector(state => state.locale.locale);

  const location = useLocation();

  const onClick = useCallback(() => {
    if (item?.children) {
      if (isOpened === null) {
        setOpened(true);
      } else {
        setOpened(prevState => !prevState);
      }
    }

    if (!isParent) changeMenuOpen(false);
  }, [isOpened, item, changeMenuOpen, isParent]);

  const childrens = useMemo(
    () =>
      item.children?.map(el => {
        if (el.access && el.access !== role) {
          return null
        }

        return (
          <ListItem item={el} key={el.title} isParent={false} changeMenuOpen={changeMenuOpen} />
        )
      }) || [],
    [item.children, role, changeMenuOpen],
  );

  const isActive = location.pathname === item.routeForActiveTab;

  return (
    <div className={b({ active: isActive })}>
      <Link to={item.route} className={b('parent', { level: item.level })} onClick={onClick}>
        <div className={b('parent', 'td')}>
          {item.img && item.whiteImg && (
            <SVGInline svg={isActive ? item.whiteImg : item.img} className={b('parent', 'img-icon').toString()} />
          )}
          {item.title === 'playerAndAgentDetail' && role !== 6 ? (
            <span className={b('parent', 'title')}>
              {locale['playerDetail'] || 'playerDetail'}
            </span>) : (<span className={b('parent', 'title')}>
              {locale[item.title] || item.title}
            </span>)
          }
        </div>

        {item?.children && (
          <SVGInline svg={arrowSvg} className={b('parent', 'img-arrow', { opened: isOpened }).toString()} />
        )}
      </Link>
      {isOpened && <div className={b('children')}>{childrens}</div>}
    </div>
  );
};

export default ListItem;
