import React, { useCallback, useRef, useState } from 'react';
import { block } from 'bem-cn';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { actions } from 'features/users/redux';
import Input from 'components/Input';
import SVG from 'components/SVG';
import useTree from 'hooks/useTree';
import searchSvg from './img/search.svg';
import SearchInput from 'components/SearchInput';

import './UsersFilterBlock.scss';

const b = block('users-filter-block');

const UsersFilterBlock = ({
  filterValue,
  changeFilterValue,
  callBack = null,
  actionProcessing,
  withoutSearchIcon = false,
  placeholder = null,
}) => {
  const dispatch = useDispatch();

  const locale = useSelector(state => state.locale.locale);
  const { getTreeById } = useTree();

  const [searchResults, setSearchResults] = useState(null)

  const activeCallback = useCallback(() => {
    if (callBack) return callBack();
    return getTreeById(filterValue || '');
  }, [callBack, filterValue, getTreeById]);

  const getUsers = useCallback(data => {
    return dispatch(actions.getUsersListForSearch(data));
  }, [dispatch]);

  const handleSearch = useCallback(
    async (value) => {
      changeFilterValue(value);

      if (value) {
        try {
          const data = await getUsers({ username: value });
          setSearchResults(data);
        } catch (error) {
          console.error(error);
        }
      }
    },
    [changeFilterValue, getUsers]
  );

  const onButtonClick = useCallback(
    event => {
      if (event.key === 'Enter') {
        activeCallback();
      }
    },
    [activeCallback]
  );

  return (
    <div className={b()}>
      <div className={b('input')}>
        <SearchInput
          value={filterValue}
          items={searchResults}
          onChange={handleSearch}
          placeholder={placeholder ?? `${locale.search} ${locale.user}`}
          onKeyDown={event => onButtonClick({ event, type: 'keydown' })}
        />
      </div>
      {!withoutSearchIcon && (
        <button
          disabled={actionProcessing || !filterValue.length}
          className={b('icon-wrapper')}
          onClick={activeCallback}>
          <SVG svgProps={{ svg: searchSvg }} className={b('icon').toString()} />
        </button>
      )}
    </div>
  );
};

export default UsersFilterBlock;
