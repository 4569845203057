import { addNotify } from 'features/notify';

const actionTypes = {
  ACTION_PROCESSING: 'tree/ACTION_PROCESSING',
  GET_USERS_BY_USER_ID_SUCCESS: 'tree/GET_USERS_BY_USER_ID_SUCCESS',
  SELECT_AGENT_ID: 'tree/SELECT_AGENT_ID',
};

const getUsersByUserId =
  ({ id, nesting, name, isRoot } = { nesting: [], isRoot: false }, isClickedByButton = false) =>
  async (dispatch, getState, extra) => {
    const { api } = extra;
    let nestingNumber = 0;
    const { id: userId, isAuth } = getState().auth;
    const { users } = getState().tree;

    if (isAuth) {
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
      const response = await api.tree.getUsersByUserId({ id: id || userId, targetName: name, isClickedByButton });

      if (response.success) {
        // Added isRoot for temporary fix for root not from auth
        if (!id || isClickedByButton || isRoot) {
          isClickedByButton &&
            dispatch({
              type: actionTypes.GET_USERS_BY_USER_ID_SUCCESS,
              payload: [],
            });
          dispatch({
            type: actionTypes.GET_USERS_BY_USER_ID_SUCCESS,
            payload: response.data,
          });
        } else {
          const mapItem = item => {
            if (item.userId !== +nesting[nestingNumber]) return item;
            if (item.userId === +nesting[nestingNumber] && nestingNumber + 1 === nesting.length) {
              return {
                ...item,
                childrens: response.data,
              };
            }
            nestingNumber += 1;
            return {
              ...item,
              childrens: item.childrens.map(mapItem),
            };
          };
          
          const convertedData = users.map(mapItem);

          dispatch({
            type: actionTypes.GET_USERS_BY_USER_ID_SUCCESS,
            payload: convertedData,
          });
        }
      } else {
        dispatch(addNotify('Error', 'error'));
      }
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    }
  };

const banUser =
  ({ id, nesting, value } = { nesting: [] }) =>
  async (dispatch, getState, extra) => {
    const { api } = extra;
    let nestingNumber = 0;
    const { users } = getState().tree;
    const { locale } = getState().locale;
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

    const response = await api.tree.changeUserStateByAction({
      id,
      value,
      name: 'ban',
    });

    if (response.success) {
      const mapItem = item => {
        if (item.userId !== +nesting[nestingNumber]) return item;
        if (item.userId === +nesting[nestingNumber] && nestingNumber + 1 === nesting.length) {
          return {
            ...item,
            isBanned: value,
          };
        }
        nestingNumber += 1;
        return {
          ...item,
          childrens: item.childrens.map(mapItem),
        };
      };
      const convertedData = users.map(mapItem);
      dispatch({
        type: actionTypes.GET_USERS_BY_USER_ID_SUCCESS,
        payload: convertedData,
      });

      const text = value ? locale.banSuccess : locale.unbanSuccess;
      dispatch(addNotify(text, 'success'));
    } else {
      dispatch(addNotify('Error', 'error'));
    }
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
  };

const selectAgentId = id => async (dispatch, getState, extra) => {
  dispatch({
    type: actionTypes.SELECT_AGENT_ID,
    payload: id,
  });
};

export { actionTypes, getUsersByUserId, banUser, selectAgentId };
