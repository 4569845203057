import React, { useEffect, useMemo } from 'react';
import block from 'bem-cn';
import SVG from 'components/SVG';
import SVGInline from 'react-svg-inline';
import { useSelector, useDispatch } from 'react-redux';
import { actions as authActions } from 'features/Auth';
import { Link } from 'react-router-dom';

import { formatNumberWithLimit } from 'shared/helpers/formatNumber';

import logoutSVG from '../../img/logout.svg';
import userSVG from '../../img/user.svg';

import { items } from '../../data';

import './HeaderDropdown.scss';

const b = block('header-dropdown');
const HeaderDropdown = ({ changeOpen }) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const locale = useSelector(state => state.locale.locale);

  useEffect(() => {
    const value = setInterval(() => dispatch(authActions.getBalance()), 3000);
    return () => {
      clearInterval(value);
    };
  }, []);

  const logout = () => {
    dispatch(authActions.logOut());
  };

  const itemsList = useMemo(
    () =>
      items.map(item => (
        <Link
          key={item.name}
          onClick={changeOpen ? () => changeOpen(false) : null}
          className={b('item')}
          to={`${item.link}/${auth.id}`}>
          <SVG className={b('item-icon').toString()} svgProps={{ svg: item.svg }} />
          <span className={b('item-value')}>{locale[item.title]}</span>
        </Link>
      )),
    [locale],
  );

  return (
    <div className={b()}>
      <div className={b('upper')}>
        <div className={b('user')}>
          <SVGInline svg={userSVG} className={b('user-img').toString()} />
          <div className={b('user-name')}>
            <span>{auth.username}</span>
            <span>ID: {auth.id}</span>
          </div>
        </div>
        <div className={b('balance')}>
          <span>{locale.balance}:</span>
          <span>{formatNumberWithLimit(auth.balance)}</span>
        </div>
      </div>
      <div className={b('bottom')}>
        {itemsList}
        <div className={b('item')} onClick={logout}>
          <SVG className={b('item-icon').toString()} svgProps={{ svg: logoutSVG }} />
          <span className={b('item-value')}>{locale.logout}</span>
        </div>
      </div>
    </div>
  );
};

export default HeaderDropdown;
