import changePasswordSVG from '../img/change-password.svg';
import editSVG from '../img/edit.svg';

export const items = [
  {
    svg: editSVG,
    title: 'edit',
    link: '/user/edit',
  },
  {
    svg: changePasswordSVG,
    title: 'changePassword',
    link: '/user/change-password',
  },
];

export const whatsappLink = 'https://wa.me/5491156447428?text=';
