import React, { useState, useCallback, useEffect } from 'react';
import block from 'bem-cn';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Button from 'components/Button';
import Input from 'components/Input';
import SVG from 'components/SVG';
import Spinner from 'components/Spinner/view/desktop';
import Switcher from 'components/Switcher';
import { actions } from 'features/users/redux';
import { userTypes } from '../../../../data';

import './ModifyAgent.scss';
import CopyToClipboard from 'components/CopyToClipboard';
import InputDate from 'components/InputDate/desktop';
import PhoneInput from 'components/PhoneInput/PhoneInput';
import { phoneCodes } from 'components/PhoneInput/PhoneInput';
import { formatNumberWithLimit } from 'shared/helpers/formatNumber';

const b = block('modify-agent');

const ModifyAgent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userId } = useParams();
  const locale = useSelector(state => state.locale.locale);
  const auth = useSelector(state => state.auth);

  const { userInfo, actionProcessing } = useSelector(state => state.users);

  const [form, setForm] = useState({
    username: '',
    email: '',
    name: '',
    surname: '',
    nameAndSurname: '',
    birthDate: '',
    phone: '',
  });

  const [withdrawalAllowed, setWithdrawalAllowed] = useState(false);
  const [depositAllowed, setDepositAllowed] = useState(false);

  const changeValue = useCallback(
    e => {
      setForm({ ...form, [e.currentTarget.name]: e.currentTarget.value });
    },
    [form],
  );

  const isEditingSelf = Number(userId) === auth.id;
  const isAgent = (Number(userInfo.role) === 6 || Number(userInfo.role) === 1) && auth.id !== Number(userId);

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      dispatch(
        actions.modifyAgent({
          data: { ...form, withdrawalAllowed, depositAllowed },
          id: userId,
          callback: () => history.push('/users/all'),
        }),
      );
    },
    [dispatch, form, withdrawalAllowed, depositAllowed, userId, history],
  );

  const changePhoneNumber = useCallback(
    value => {
      setForm({ ...form, phone: value });
    },
    [form]
  );

  const changeDob = useCallback(
    value => {
      setForm({ ...form, birth_date: value });
    },
    [form]
  );

  const changeNameAndSurname = useCallback(
    e => {

      setForm({ ...form, nameAndSurname: e.currentTarget.value });
    },
    [form]
  );

  const phoneNumber = userInfo?.user?.phone;
  const birthDate = userInfo?.user?.birth_date;
  const modifiedPhoneNumber = phoneNumber?.replace('+54', '');
  const disabled = form.username.length === 0;

  useEffect(() => {
    setForm({
      email: userInfo?.email,
      name: userInfo?.name,
      surname: userInfo?.surname,
      username: userInfo?.username,
      nameAndSurname: `${userInfo?.name} ${userInfo?.surname}`,
      birthDate,
      phone: modifiedPhoneNumber,
    });
    setWithdrawalAllowed(userInfo.isWithdrawalAccess);
    setDepositAllowed(userInfo.isDepositAccess);
  }, [birthDate, modifiedPhoneNumber, userInfo]);

  useEffect(() => {
    dispatch(actions.getUserInfo({ id: userId }));
  }, [dispatch, userId]);

  if (actionProcessing) return <Spinner isLoading={actionProcessing} />;

  return (
    <div className={b()}>
      <form className={b('form')} onSubmit={onSubmit}>
        <div className={b('header')}>{locale.userEdit}</div>
        <div className={b('user-block')}>
          <div className={b('user-block-left')}>
            <SVG svgProps={{ svg: userTypes[userInfo.role].icon }} className={b('user-block-icon')} />
            <div className={b('user-block-info')}>
              <div className={b('username-block')}>
                <span>{userInfo.username}</span>

                <CopyToClipboard text={userInfo.username} />
              </div>
              <div className={b('user-block-info-type', { role: userInfo.role })}>{userTypes[userInfo.role].type}</div>
            </div>
          </div>
          <div className={b('user-block-right')}>
            <span>{locale.id}</span>
            <div className={b('id-block')}>
              <span className={b('id')}>{userId}</span>
              <CopyToClipboard text={userId} />
            </div>
          </div>
          <div className={b('user-block-right')}>
            <span>{locale.balance}:</span>
            <span>{formatNumberWithLimit(userInfo.balance)}</span>
          </div>
        </div>

        {!isAgent && !isEditingSelf && (
          <div className={b('fields')}>
            <div className={b('form', 'input-block')}>
              <div className={b('input')}>
                <span className={b('form', 'input-block-title')}>{locale.username}</span>
                <Input
                  validState={form.username.length > 0 ? 1 : 3}
                  placeholder={locale.username}
                  onChange={changeValue}
                  value={form.username}
                  name="username"
                />
              </div>
            </div>

            <div className={b('form', 'input-block')}>
              <div className={b('input')}>
                <span className={b('form', 'input-block-title')}>{locale.dob}</span>
                <div className={b('input-date')}>

                  <InputDate withManualTyping selected={form?.birthDate && new Date(form?.birthDate)} onChange={changeDob} />
                </div>
              </div>
            </div>
            <div className={b('form', 'input-block')}>
              <span className={b('form', 'input-block-title')}>{locale.nameAndSurname}</span>
              <div className={b('input')}>
                <Input placeholder={locale.nameAndSurname} onChange={changeNameAndSurname} value={form.nameAndSurname} name="nameAndSurname" />
              </div>
            </div>

            <div className={b('form', 'input-block')}>
              <span className={b('form', 'input-block-title')}>{locale.phone}</span>
              <div className={b('input')}>
                <PhoneInput
                  changePhoneNumber={changePhoneNumber}
                  phone={form.phone}
                  countryCode={phoneCodes.ARGENTINA.code}
                />
              </div>
            </div>

          </div>
        )}

        {(isAgent || isEditingSelf) && (
          <>
            <div className={b('fields')}>
              <div className={b('form', 'input-block')}>
                <div className={b('input')}>
                  <span className={b('form', 'input-block-title')}>{locale.username}</span>
                  <Input
                    validState={form.username.length > 0 ? 1 : 3}
                    placeholder={locale.username}
                    onChange={changeValue}
                    value={form.username}
                    name="username"
                  />
                </div>
              </div>
              <div className={b('form', 'input-block')}>
                <span className={b('form', 'input-block-title')}>{locale.name}</span>
                <div className={b('input')}>
                  <Input placeholder={locale.name} onChange={changeValue} value={form.name} name="name" />
                </div>
              </div>
              <div className={b('form', 'input-block')}>
                <div className={b('input')}>
                  <span className={b('form', 'input-block-title')}>{locale.email}</span>
                  <Input placeholder={locale.email} onChange={changeValue} value={form.email} name="email" />
                </div>
              </div>
              <div className={b('form', 'input-block')}>
                <span className={b('form', 'input-block-title')}>{locale.surname}</span>
                <div className={b('input')}>
                  <Input placeholder={locale.surname} onChange={changeValue} value={form.surname} name="surname" />
                </div>
              </div>
            </div>
            <div className={b('form', 'switchers')}>
              <div className={b('form', 'switchers-block')}>
                <div
                  className={b('form', 'switchers-title')}
                  dangerouslySetInnerHTML={{ __html: locale.withdrawalAllowed }}
                />
                <div className={b('form', 'switchers-item')}>
                  <Switcher isActive={withdrawalAllowed} onSwitch={setWithdrawalAllowed} />
                </div>
              </div>
              <div className={b('form', 'switchers-block')}>
                <div
                  className={b('form', 'switchers-title')}
                  dangerouslySetInnerHTML={{ __html: locale.depositAllowed }}
                />
                <div className={b('form', 'switchers-item')}>
                  <Switcher isActive={depositAllowed} onSwitch={setDepositAllowed} />
                </div>
              </div>
            </div>
          </>
        )}

        <div className={b('bottom')}>
          <Button color="full-transparent" callBack={history.goBack} text={locale.cancel} size="low" />
          <Button disabled={disabled} type="submit" size="low" text={locale.save} />
        </div>
      </form>
    </div>
  );
};

export default ModifyAgent;
