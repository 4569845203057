import React from 'react';
import block from 'bem-cn';
import { Link } from 'react-router-dom';

import ListItem from './ListItem';
import logoPNG from 'shared/img/logo.png';

import './Sidebar.scss';

import { sidebarMenu } from '../../data';

const b = block('sidebar');

const Sidebar = ({ changeMenuOpen = () => null }) => {
  return (
    <div className={b()}>
      <Link className={b('logo-wrapper')} to="/">
        <img src={logoPNG} className={b('logo')} alt="logo" />
      </Link>
      <div className={b('menu')}>
        {sidebarMenu.map(el => (
          <ListItem item={el} key={el.title} changeMenuOpen={changeMenuOpen} />
        ))}
      </div>
    </div>
  );
};

export default Sidebar;