import { actionTypes } from './actions';
import { initialState } from './initial';

export function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ACTION_PROCESSING:
      return {
        ...state,
        actionProcessing: action.payload,
      };
    case actionTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        actionProcessing: false,
      };

    case actionTypes.GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        usersList: action.payload,
        actionProcessing: false,
      };

    case actionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        actionProcessing: false,
        newPassword: action.payload,
      };

    case actionTypes.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        actionProcessing: false,
        userInfo: action.payload,
        usersInfo: {
          ...state.usersInfo,
          [action.payload.id]: action.payload,
        },
      };

    case actionTypes.USER_INFO_DELETE:
      return {
        ...state,
        actionProcessing: false,
        userInfo: {},
        usersInfo: null,
      };

    case actionTypes.DATA_REFRESH: {
      return {
        ...state,
        actionProcessing: false,
        isNeedToRefresh: !state.isNeedToRefresh,
      };
    }

    case actionTypes.GET_BANNED_PROVIDERS: {
      return {
        ...state,
        actionProcessing: false,
        bannedProviders: action.payload,
      };
    }

    case actionTypes.SET_BANNED_PROVIDERS: {
      return {
        ...state,
        actionProcessing: false,
        userInfo: {
          ...state.userInfo,
          bannedProviders: action.payload,
        },
      };
    }

    default:
      return { ...state };
  }
}
